// pages/SuccessPage.js
import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import "../styles/Choicepage.css";
import "../styles/main.css";
import { useGlobalState } from "../context/GlobalStateContext";
import { useNavigation } from "../context/NavigationContext";

const SuccessPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state || {};
  const { resetAllState } = useNavigation();
  const status_texts = {
    eligible:
      "You fulfil the minimum eligiblity requirements for social-housing assistance",
    caseworker: "You have been referred to a case worker",
  };
  const { resetAllFields } = useGlobalState();
  const what_to_do = {
    eligible:
      "You can give this sharecode and your date of birth to your potential social-housing provider.\
 	<br><br>They will be able to see that you fulfil the minimum eligibility requirements for social-housing assistance.\n\
 They won't be able to see your immigration status or type.\
<br><br>Please note that there might be <strong>additional requirements</strong> which you might need to fulfil. These can be based on where you live or where you want to apply for social housing.\
 This sharecode is not a guarantee for getting social housing. It only tells if you fulfil the minimum eligibility requirements or not.",
    caseworker:
      "You can give this sharecode and your date of brith to your potential social-housing provider. <br><br>They will be able to see that you fulfil <strong>some</strong> of the minimum eligiblity requirements but they would need to see more documentation to fully establish your eligibility.\
<br><br>They wont be able to see your immigration status or type.",
  };

  const handleNext = () => {
    resetAllFields();
    resetAllState();
    navigate("/");
  };

  const printPage = () => {
    const printWindow = window.open("", "_blank");
    const printContent = `
      <html>
        <head>
          <title>Your Sharecode</title>
          <link rel="stylesheet" type="text/css" href="/styles/Choicepage.css" />
          <link rel="stylesheet" type="text/css" href="/styles/main.css" />
        </head>
        <body>
          <h1>Your Sharecode</h1>
          <p>Your sharecode is: <strong style="font-size: 19px;">${
            data.content.sharecode
          }</strong></p>
          <p>It is valid until: <strong>${
            data.content.sharecode_validity_date
          }</strong></p>
          <p>Name: <strong>${data.content.first_name} ${
      data.content.last_name
    }</strong></p>
          <p>Status: <strong>${status_texts[data.content.status]}</strong></p>
          <br>
          <p><strong>What to do next?</strong></p>
          <p>${what_to_do[data.content.status]}</p>
        </body>
      </html>
    `;

    printWindow.document.open();
    printWindow.document.write(printContent);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div className="container">
      <Header />
      <h1>
        {data.isnew === 0
          ? "You already have a sharecode created with the given email"
          : "Sharecode created successfully"}
      </h1>
      <p>
        Your sharecode is:{" "}
        <strong style={{ fontSize: "19px" }}>{data.content.sharecode}</strong>
      </p>
      <p>
        It is valid until:{" "}
        <strong>{data.content.sharecode_validity_date}</strong>
      </p>
      <p>
        Name:{" "}
        <strong>
          {data.content.first_name + " " + data.content.last_name}
        </strong>
      </p>
      <p>
        Status: <strong>{status_texts[data.content.status]}</strong>
      </p>
      <br></br>
      <p>
        <strong>What to do next? </strong>

        <p
          dangerouslySetInnerHTML={{ __html: what_to_do[data.content.status] }}
        ></p>
      </p>
      <div className="button-container">
        <button id="nextButton" onClick={handleNext}>
          Start Over
        </button>
        <button id="backButton" onClick={printPage}>
          Print this page
        </button>
      </div>
    </div>
  );
};

export default SuccessPage;
