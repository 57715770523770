// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ChoicePage from './pages/ChoicePage';
import DecisionPage from './pages/DecisionPage';
import ShareCodeFormPage from './pages/ShareCodeFormPage';
import DisplayShareCodePage from './pages/DisplayShareCodePage';
import { NavigationProvider } from './context/NavigationContext';
import LandingPage from './pages/LandingPage';
import EnterOtpPage from './pages/EnterOtpPage';
import SuccessPage from './pages/SuccessPage';
import ErrorPage from './pages/ErrorPage';
import VerifyPage from './pages/VerifyPage';
import ConfirmPage from './pages/ConfirmPage';
import { GlobalStateProvider } from './context/GlobalStateContext';

const App = () => {
  return (
    <Router>
      <GlobalStateProvider>
        <NavigationProvider> {/* Wrap with NavigationProvider */}
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/create/:pageId" element={<ChoicePage />} />
            <Route path="/create/decision" element={<DecisionPage />} />
            <Route path="/sharecodeform" element={<ShareCodeFormPage />} />
            <Route path="/verify" element={<VerifyPage />} />
            <Route path="/confirm" element={<ConfirmPage />} />
            <Route path="/enterotp" element={<EnterOtpPage />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/error" element={<ErrorPage />} />
          </Routes>
        </NavigationProvider>
      </GlobalStateProvider>
    </Router>
  );
};

export default App;
