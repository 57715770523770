// pages/EnterOtpPage.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import "../styles/Choicepage.css";
import LoadingSpinner from "../components/LoadingSpinner";
import { useGlobalState } from "../context/GlobalStateContext";

const EnterOtpPage = () => {
  const navigate = useNavigate();
  const { email, firstName, lastName, status } = useGlobalState();

  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOtpSubmit = async () => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 100));

    try {
      const response = await fetch(
        "https://tough-jazmin-primepersonacreator-c22863b8.koyeb.app/create",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email,
            otp,
            sharecode_data: {
              first_name: firstName,
              last_name: lastName,
              email,
              status,
            },
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setLoading(false);
        navigate("/success", { state: { data } });
      } else {
        setLoading(false);
        navigate("/error", {
          state: { message: "OTP verification failed", type: "create" },
        });
      }
    } catch (error) {
      setLoading(false);
      navigate("/error", {
        state: { message: "Error verifying OTP", type: "create" },
      });
    }
  };

  return (
    <div className="container">
      <Header />
      {loading ? (
        <LoadingSpinner text="Verifying OTP" />
      ) : (
        <div>
          <h1>We have sent you a 6 digit OTP (one-time password)</h1>
          <p>
            We have emailed you a 6 digit OTP on the email address you provided
            us.<br></br>
            <br></br>
            Please look for an email from ireshasharecode@gmail.com in your
            inbox. If you can’t find it in your inbox, make sure to check your
            spam/junk folder too.
          </p>
          <input
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <br></br>
          <br></br>
          <div className="button-container">
            <button
              id="nextButton"
              onClick={handleOtpSubmit}
              disabled={otp === ""}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnterOtpPage;
