// pages/VerifyPage.js
import React from "react";
import Header from "../components/Header";
import "../styles/main.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import LoadingSpinner from "../components/LoadingSpinner";

const VerifyPage = () => {
  const navigate = useNavigate();
  const [shareCode, setshareCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [verifyFormData, setverifyFormData] = useState({
    firstName: "",
    lastName: "",
    day: "",
    month: "",
    year: "",
    email: "",
    confirmEmail: "",
    confirm: false,
  });
  const isDateValid = () => {
    const { day, month, year } = verifyFormData;
    const date = new Date(`${year}-${month}-${day}`);
    return (
      date instanceof Date &&
      !isNaN(date) &&
      date.getDate() === parseInt(day) &&
      date.getMonth() + 1 === parseInt(month) &&
      date.getFullYear() === parseInt(year)
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setverifyFormData({ ...verifyFormData, [name]: value });
  };
  const handleShareCodeSubmit = async () => {
    setLoading(true);
    if (!isDateValid()) {
      setLoading(false);
      alert("Please enter a valid date.");
      return;
    }
    try {
      const response = await fetch(
        "https://tough-jazmin-primepersonacreator-c22863b8.koyeb.app/verify",
        {
          method: "POST",

          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            sharecode: shareCode,
          }),
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setLoading(false);
        navigate("/confirm", { state: { data } });
      } else if (response.status === 404) {
        setLoading(false);
        navigate("/error", {
          state: {
            message:
              "Sorry, we are unable to verify the sharecode which you gave us.\
<br><br>Please make sure that the sharecode and the date of brith of the applicant are typed in correctly. ",
            type: "sharecode",
          },
        });
      }
    } catch (error) {
      setLoading(false);
      navigate("/error", {
        state: { message: "Error verifying Sharecode", type: "sharecode" },
      });
    }
  };
  return (
    <div className="container">
      <Header />
      {loading ? (
        <LoadingSpinner text="Verifying sharecode" />
      ) : (
        <div className="container">
          <h2>IRESHA Sharecode Verification</h2>
          <label>
            <strong>
              Please enter the sharecode supplied by the applicant
            </strong>{" "}
          </label>
          <input
            type="text"
            placeholder="Enter Sharecode"
            value={shareCode}
            onChange={(e) => setshareCode(e.target.value)}
          />
          <br></br>
          <label>
            <strong>Please enter the date of birth of the applicant </strong>{" "}
          </label>
          <span className="date-picker">
            <input
              className="day-picker"
              type="text"
              name="day"
              placeholder="DD"
              value={verifyFormData.day}
              onChange={handleInputChange}
            />
            <label></label>
            <input
              className="month-picker"
              type="text"
              name="month"
              placeholder="MM"
              value={verifyFormData.month}
              onChange={handleInputChange}
            />
            <label></label>
            <input
              className="year-picker"
              type="text"
              name="year"
              placeholder="YYYY"
              value={verifyFormData.year}
              onChange={handleInputChange}
            />
          </span>
          <br />
          <br />
          <div className="button-container">
            <button
              id="backButton"
              onClick={() => {
                navigate("/");
              }}
            >
              Go Back
            </button>
            <button
              id="nextButton"
              onClick={handleShareCodeSubmit}
              disabled={
                verifyFormData.day === "" ||
                verifyFormData.month === "" ||
                verifyFormData.year === "" ||
                shareCode === ""
              }
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VerifyPage;
