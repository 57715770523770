// components/TextDisplay.js
import React, { useState, useEffect } from "react";
import "../styles/TextDisplay.css";

const TextDisplay = ({ sections, pageId }) => {
  function parseContent(text) {
    const urlPattern =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])|(\bwww\.[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])|(\b[-A-Z0-9+&@#\/%=~_|]*\.[a-z]{2,}\b)/gi;
    const lines = text.split("\n\n");

    return lines.map((line, index) => {
      if (line.startsWith("-")) {
        const bullets = line
          .split("\n")
          .map((bullet) => bullet.replace(/^-/, "").trim())
          .filter((bullet) => bullet);
        return (
          <p>
            <ul key={index}>
              {bullets.map((bullet, bulletIndex) => (
                <li
                  key={bulletIndex}
                  dangerouslySetInnerHTML={{
                    __html: bullet.replace(urlPattern, (url) => {
                      let hyperlink = url;
                      if (!hyperlink.match(/^(https?|ftp):\/\//)) {
                        hyperlink = "http://" + hyperlink;
                      }
                      return `<a href="${hyperlink}" target="_blank" rel="noopener noreferrer">${url}</a>`;
                    }),
                  }}
                ></li>
              ))}
            </ul>
          </p>
        );
      } else {
        const paragraphWithLinks = line.replace(urlPattern, (url) => {
          let hyperlink = url;
          if (!hyperlink.match(/^(https?|ftp):\/\//)) {
            hyperlink = "http://" + hyperlink;
          }
          return `<a href="${hyperlink}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        });

        return (
          <p
            key={index}
            dangerouslySetInnerHTML={{ __html: paragraphWithLinks }}
          ></p>
        );
      }
    });
  }
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {
    setIsExpanded(false);
  }, [pageId]);

  return (
    <div className="text-display">
      <div className="text-display-header" onClick={toggleExpand}>
        <span
          className={`dropdown-icon ${isExpanded ? "expanded" : ""}`}
        ></span>
        <h5 className="whyarewe">Why we are asking this question?</h5>
      </div>
      {isExpanded && (
        <div className="text-display-content">
          {sections.map((section, index) => (
            <div key={index} className="text-display-section">
              <p className="section-title">{section.title}</p>
              <p className="section-content">{parseContent(section.content)}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TextDisplay;
