import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { useNavigation } from "../context/NavigationContext";
import { useGlobalState } from "../context/GlobalStateContext";
//import '../styles/Choicepage.css';
import "../styles/main.css";

const decisions = {
  eligible: {
    text: "You fulfil the minimum eligiblity requirements for social-housing assistance",
    additional_text:
      "Based on your answers, you fulfil the minimum eligiblity requirements for seeking social-housing assistance. <br><br>Please note that there might be additional requirements which you might need to fulfil. These can be based on where you live or where you want to apply for social housing.",
    todo: "You can go ahead and create a sharecode. This sharecode can be shared with your housing association or local authority.",
  },
  not_eligible: {
    text: "You do not meet the minimum requirements to be eligible for housing assistance",
    additional_text:
      "Based on your answers, you are not eligible for seeking housing assistance. You cannot create a sharecode. Sorry!",
  },
  caseworker: {
    text: "You have been referred to a case worker",
    additional_text:
      "Based on your answers, it seems we need a caseworker to decide your minimum eligibility.\
<br><br>You can still create a sharecode but you might need to answer additional questions or present additional documents to the caseworker inorder to help them decide if you are eligibile to apply for social housing.",
  },
};

const DecisionPage = () => {
  const navigate = useNavigate();
  const { resetAllFields } = useGlobalState();
  //const { status } = location.state || {};
  const { navigateTo, resetAllState } = useNavigation();
  const { status, setAction } = useGlobalState();

  const decision = decisions[status];

  const handleNext = () => {
    resetAllFields();
    resetAllState();
    navigate("/");
  };

  const handleCreateShareCode = () => {
    if (status === "eligible" || status === "caseworker") {
      setAction("creation");
      navigate("/sharecodeform");
    }
  };

  // Render a message if no status is passed or if it’s an invalid status
  if (!decision) {
    return <p>Invalid or missing status</p>;
  }

  return (
    <div className="container">
      <Header />
      <h1>Decision: {decision.text}</h1>

      <div style={{ fontSize: "17px" }}>
        <p>
          <strong>What this means for you? </strong>

          <p dangerouslySetInnerHTML={{ __html: decision.additional_text }}></p>
        </p>

        {decision.todo && (
          <>
            <p>
              <strong>What to do next? </strong>
            </p>
            <p dangerouslySetInnerHTML={{ __html: decision.todo }}></p>
          </>
        )}
      </div>

      <div className="button-container">
        <button id="backButton" onClick={() => navigateTo("back")}>
          Back
        </button>
        {status === "not_eligible" ? (
          <button id="nextButton" onClick={handleNext}>
            Start Over
          </button>
        ) : (
          <button id="nextButton" onClick={handleCreateShareCode}>
            Create Sharecode
          </button>
        )}
      </div>
    </div>
  );
};

export default DecisionPage;
