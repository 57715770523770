// pages/SuccessPage.js
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import "../styles/Choicepage.css";
import "../styles/main.css";

const ConfirmPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state || {};
  const status_texts = {
    eligible:
      "The applicant fulfils the minimum eligiblity requirements for social-housing assistance",
    caseworker: "The applicant has been referred to a case worker",
  };

  const handleNext = () => {
    navigate("/verify");
  };

  return (
    <div className="container">
      <Header />
      <h1>Sharecode verified</h1>
      <p>
        Sharecode: <strong>{data.sharecode}</strong>
      </p>
      <p>
        It is valid until: <strong>{data.sharecode_validity_date}</strong>
      </p>
      <p>
        Name: <strong>{data.first_name + " " + data.last_name}</strong>
      </p>
      <p>
        Status: <strong>{status_texts[data.status]}</strong>
      </p>
      <br></br>
      <button id="nextButton" onClick={handleNext}>
        Start Over
      </button>
    </div>
  );
};

export default ConfirmPage;
