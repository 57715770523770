// context/NavigationContext.js
import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const NavigationContext = createContext();

export const useNavigation = () => useContext(NavigationContext);

export const NavigationProvider = ({ children }) => {
  const [history, setHistory] = useState(["Landing"]);
  const [selections, setSelections] = useState({});
  const [pageData, setPageData] = useState({});
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [dob, setDob] = useState("");
  const [status, setStatus] = useState("");
  const [action, setAction] = useState("");
  const [confirm, setConfirm] = useState(false);
  const navigateTo = (page, status) => {
    if (page === "back") {
      setHistory((prev) => prev.slice(0, -1));
      navigate(-1);
    } else {
      setHistory((prev) => [...prev, page]);
      if (page === "verifyPage") {
        navigate("/verify");
      } else if (page === "DecisionPage") {
        navigate("/create/decision", { state: { status } });
      } else {
        navigate(`/create/${page}`);
      }
    }
  };

  const saveSelection = (page, option) => {
    setSelections((prev) => ({ ...prev, [page]: option }));
  };

  const resetAllState = () => {
    setSelections({});
    setPageData({});
    setHistory(["Landing"]);
  };

  const setPageInfo = (page, data) => {
    setPageData((prev) => {
      const currentPageData = prev[page] || {};
      if (JSON.stringify(currentPageData) !== JSON.stringify(data)) {
        return { ...prev, [page]: data };
      }
      return prev;
    });
  };

  const getPageInfo = (page) => pageData[page] || {};

  return (
    <NavigationContext.Provider
      value={{
        history,
        navigateTo,
        saveSelection,
        selections,
        setSelections,
        resetAllState,
        setPageInfo,
        getPageInfo,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        dob,
        setDob,
        day,
        setDay,
        month,
        setMonth,
        year,
        setYear,
        email,
        setEmail,
        status,
        setStatus,
        action,
        setAction,
        confirm,
        setConfirm,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
