// context/GlobalStateContext.js
import React, { createContext, useContext, useState } from "react";

// Create the context
const GlobalStateContext = createContext();

// Custom hook to access the global state and functions
export const useGlobalState = () => useContext(GlobalStateContext);

// Provider component for managing individual state variables
export const GlobalStateProvider = ({ children }) => {
  // Individual states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [action, setAction] = useState("creation");
  const [confirm, setConfirm] = useState(false);
  const [brp, setBrp] = useState(false);
  const [brpInput, setBrpInput] = useState("");

  const [step, setStep] = useState(1);

  // Reset function to reset each state individually
  const resetAllFields = () => {
    setFirstName("");
    setLastName("");
    setDay("");
    setMonth("");
    setYear("");
    setDob("");
    setEmail("");
    setStatus("");
    setAction("");
    setConfirm(false);
    setBrp(false);
    setStep(1);
    setBrpInput("");
  };

  return (
    <GlobalStateContext.Provider
      value={{
        firstName,
        setFirstName,
        lastName,
        setLastName,
        day,
        setDay,
        month,
        setMonth,
        year,
        setYear,
        dob,
        setDob,
        email,
        setEmail,
        status,
        setStatus,
        action,
        setAction,
        confirm,
        setConfirm,

        step,
        setStep,
        brp,
        setBrp,
        brpInput,
        setBrpInput,

        resetAllFields, // Export the reset function
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
