import React from "react";
import "../styles/HelpTooltip.css";

function parseContent(text) {
  const urlPattern =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])|(\bwww\.[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])|(\b[-A-Z0-9+&@#\/%=~_|]*\.[a-z]{2,}\b)/gi;

  const lines = text.split("\n\n");

  return lines.map((line, index) => {
    if (line.startsWith("-")) {
      const bullets = line
        .split("\n")
        .map((bullet) => bullet.replace(/^-/, "").trim())
        .filter((bullet) => bullet);
      return (
        <ul key={index}>
          {bullets.map((bullet, bulletIndex) => (
            <li
              key={bulletIndex}
              dangerouslySetInnerHTML={{
                __html: bullet.replace(urlPattern, (url) => {
                  let hyperlink = url;
                  if (!hyperlink.match(/^(https?|ftp):\/\//)) {
                    hyperlink = "http://" + hyperlink;
                  }
                  return `<a href="${hyperlink}" target="_blank" rel="noopener noreferrer">${url}</a>`;
                }),
              }}
            ></li>
          ))}
        </ul>
      );
    } else {
      const paragraphWithLinks = line.replace(urlPattern, (url) => {
        let hyperlink = url;
        if (!hyperlink.match(/^(https?|ftp):\/\//)) {
          hyperlink = "http://" + hyperlink;
        }
        return `<a href="${hyperlink}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });

      return (
        <p
          key={index}
          dangerouslySetInnerHTML={{ __html: paragraphWithLinks }}
        ></p>
      );
    }
  });
}

const HelpTooltip = ({ sections }) => {
  return (
    <div className="help-tooltip-container">
      <div className="help-button">?</div>
      <div className="tooltip-content">
        {sections.map((section, index) => (
          <div key={index} className="tooltip-section">
            <strong>{section.title}</strong>
            <div>{parseContent(section.content)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HelpTooltip;
